<template>
  <b-modal
    id="modal-cancelation"
    no-close-on-backdrop
    hide-header-close
    hide-footer
    size="md"
    centered
    @hidden="onHideActions"
  >
    <template #modal-title>
      <p class="h4">
        {{ actionData.modalTitle }}
      </p>
    </template>

    <FormulateForm
      ref="cancelationForm"
      name="cancelationForm"
    >
      <b-container>
        <b-row align-h="center">
          <b-col
            md="12"
            class="d-flex flex-column"
          >
            <FormulateInput
              id="observation-input"
              v-model="form.observation"
              name="observation"
              class="w-100"
              type="textarea"
              :validation="action === 'Disapprove' || action === 'AskChanges' ? 'required' : ''"
              rows="4"
              :label="$t('Observação')"
            />
          </b-col>
          <b-col md="12">
            <b-card-actions
              :show-loading="busy"
              action-collapse
              :title="$t('Anexos')"
            >
              <files-form
                :files="files"
                :is-read-only="false"
                @set-files="setFiles"
                @remove-file="removeFile"
              />
            </b-card-actions>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col
            class="d-flex justify-content-end"
            style="gap: 10px"
          >
            <e-button
              :text="$t('Voltar')"
              :text-shortcuts="['ESC']"
              variant="danger"
              @click="onHideModal"
            />
            <e-button
              variant="primary"
              type="submit"
              :busy="busy"
              :text="$t('Confirmar')"
              @click="onConfirm"
            />
          </b-col>
        </b-row>
      </b-container>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import { formulateHelper, uploader } from '@/mixins'
import { mapState, mapActions } from 'vuex'
import FilesForm from '@/views/components/forms/FilesForm.vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'

export default {
  components: { BModal, BContainer, BRow, BCol, EButton, FilesForm, BCardActions },

  mixins: [formulateHelper, uploader],

  data() {
    return {
      busy: false,
      modalResolve: null,
      modalReject: null,
      action: null,
      form: {
        id: null,
        observation: '',
        stepId: null
      },
    }
  },

  computed: {
    ...mapState('pages/security/approvalFlowTransaction', {
      stFiles: 'files',
    }),
    files: {
      get() {
        return this.stFiles
      },
      set(val) {
        this.setFiles(val)
      },
    },
    actionData() {
      switch (this.action) {
        case 'Approve':
          return {
            modalTitle: this.$t('Aprovar fluxo'),
            apiUrl: `/api/approval-flows/approve-transaction/${this.form.id}`,
          }
        case 'Disapprove':
          return {
            modalTitle: this.$t('Reprovar fluxo'),
            apiUrl: `/api/approval-flows/disapprove-transaction/${this.form.id}`,
          }
        case 'AskChanges':
          return {
            modalTitle: this.$t('Solicitar alterações'),
            apiUrl: `/api/approval-flows/ask-for-change/${this.form.id}`,
          }
        default:
          return {
            modalTitle: '',
            apiUrl: '',
          }
      }
    },
  },

  methods: {
    ...mapActions('pages/security/approvalFlowTransaction', [
      'setFiles',
      'deleteLocalFile',
      'uploadDocuments',
      'cleanFileState'
    ]),
    async removeFile(file) {
      this.deleteLocalFile(file)
    },
    async onConfirm() {
      try {
        this.$refs.cancelationForm.showErrors()
        if (this.$refs.cancelationForm.hasErrors) {
          this.showInvalidDataMessage()
          return
        }
        this.busy = true

        const { data } = await this.$http.put(this.actionData.apiUrl, this.form)
        await this.actionsAfterSave(this.form, data)
        this.showSuccess({ message: this.$t('Ação realizada com sucesso.') })

        this.onHideModal()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.busy = false
      }
    },

    async actionsAfterSave(data, saveMessage) {
      this.showSuccess({ message: this.$t('Salvo com sucesso.') })

      if (saveMessage?.message) {
        this.showWarning({ message: this.$t(saveMessage?.message) })
      }

      let hasFileError = false
      try {
        await this.uploadDocuments(data?.stepId)
        this.cleanFileState()
      } catch (uploadError) {
        this.showError({
          title: this.$t('Ocorreu um erro ao fazer upload dos anexos'),
          error: uploadError,
        })
        hasFileError = true
      }

      if (!hasFileError && (this.stFiles.some(f => !f.id))) {
        this.showSuccess({ message: this.$t('Anexos salvos com sucesso.') })
      }

      this.$emit('after-confirm')
    },

    resetForm() {
      this.form = {
        id: '',
        observation: '',
      }
      this.action = null
    },

    onHideModal() {
      this.$bvModal.hide('modal-cancelation')
    },

    onHideActions() {
      if (this.modalReject) this.modalReject({ message: 'Autorização cancelado' })
    },

    /// action: 'Approve' | 'Disapprove' | 'AskChanges'
    show(action, approvalId, stepId) {
      this.$bvModal.show('modal-cancelation')
      this.resetForm()
      this.form.id = approvalId
      this.form.stepId = stepId
      this.action = action
      this.onFocusInput()
    },

    onFocusInput() {
      setTimeout(() => {
        this.focusInput('#observation-input')
      }, 400)
    },
  },
}
</script>

<style lang="scss" scoped></style>
